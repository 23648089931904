<template>
  <div>
    <v-dialog
        v-model="ticketModal"
        max-width="800"
        @input="$emit('close')"
    >
      <v-card class="pa-0">
        <div class="pa-6">
          <div class="d-flex justify-space-between align-center border-bottom mb-4">
            <h3 class="font-semibold text-blue pb-2">
              Select Membership Package
            </h3>
            <div class="pointer" @click="$emit('close')">
              <ModalCloseIcon/>
            </div>
          </div>
          <div>
            <div style="max-width: 11rem">
              <label for="">
                Membership Start Date
              </label>
              <date-field
                  v-model="start_date"
                  :backFill="checkBackfillPermission($modules.memberships.management.slug)"
                  :rules="[(v) => !!v || 'Start Date is required']"
                  dense
                  label=""
              />
            </div>
            <v-row>
              <v-col v-for="membershipPackage in membership.packages" :key="membershipPackage.id" lg="4" md="6" sm="12">
                <div class="package-card">
                  <div class="d-flex justify-space-between flex-column">
                    <div class="py-5 package-title">
                      <h4 class="font-semibold text-blue line-clamp-1">
                        {{ membershipPackage.name }}
                      </h4>
                    </div>
                    <div class="p-3">
                      <div class="d-flex justify-space-between align-center">
                        <p class="mb-0">
                          Validity
                        </p>
                        <p class="font-semibold mb-0">
                          {{ membershipPackage.duration }}
                        </p>
                      </div>
                      <div class="d-flex justify-space-between align-center mt-2">
                        <p class="mb-0">
                          Price
                        </p>
                        <p class="font-semibold mb-0">
                          {{ membershipPackage.price | toCurrency }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-center align-center flex-column mt-4">
                    <v-btn
                        class="white--text teal-color px-8 rounded-2"
                        height="36"
                        style="width: fit-content"
                        text
                        @click="showPlans(membershipPackage)"
                    >
                      Enroll Now
                    </v-btn>

                    <!--                    <v-btn-->
                    <!--                        class="font-12 text_capitalize installment-button"-->
                    <!--                        height="32"-->
                    <!--                        text-->
                    <!--                        @click="showPlans(membershipPackage)"-->
                    <!--                    >-->
                    <!--                      Instalment Plans ->-->
                    <!--                    </v-btn>-->
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog
        v-model="installmentPlansModal"
        max-width="700"
    >
      <v-card class="pa-0">
        <div class="pa-6">
          <div class="d-flex justify-space-between align-center border-bottom">
            <h3 class="font-semibold text-blue pb-2">
              Membership Installment Plans
            </h3>
            <div class="pointer" @click="installmentPlansModal=false">
              <ModalCloseIcon/>
            </div>
          </div>
          <div class="mt-4">
            <v-row v-if="selectedPackage">
              <v-col v-for="plan in selectedPackage.plans" :key="plan.id" lg="4" md="6">
                <div class="package-card pa-3">
                  <div class="border-bottom mb-2">
                    <p class="mb-1 font-12">
                      {{ plan.divisor }} Installments
                    </p>
                    <p class="mb-2 font-18 font-semibold">
                      {{ plan.name }}
                    </p>
                  </div>
                  <ul class="installment-list">
                    <li>
                      {{ plan.divisor }} Installments
                    </li>
                    <li>
                      {{ plan.price | toCurrency }}
                    </li>
                  </ul>
                  <div class="d-flex justify-center mt-8 mb-2">
                    <v-btn
                        class="white--text teal-color rounded-2"
                        height="40"
                        text
                        @click="enrollNow(plan)"
                    >
                      Select this Plan
                    </v-btn>
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card>

    </v-dialog>
    <ShopCustomerPopup
        v-if="showCustomerPopup"
        :promotions="promotions"
        :bookingData="bookingForm"
        bookingType="membership"
        :max-customers="getMaxCustomers()"
        :show-customer-popup="showCustomerPopup"
        @close="closeCustomerPopup"
        @setCustomers="setCustomers"
    />
    <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="closeOfferPopup" />
  </div>
</template>
<script>
import ModalCloseIcon from "@/assets/images/misc/modal-close.svg";
import moment from "moment";
import ShopCustomerPopup from "@/components/Shop/ShopCustomerPopup.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";

export default {
  name: "MembershipPackagesModal",
  components: {PackageCardPopup, ShopCustomerPopup, ModalCloseIcon},
  props: {
    packagesModal: {
      type: Boolean,
      default: false
    },
    membership: {
      type: Object,
      required: true
    }
  },
  computed: {
    getCurrentVenueId(){
      if(this.membership && this.membership.venue_id){
        return this.membership.venue_id;
      }
      return this.$store.getters.svid?this.$store.getters.svid:this.$store.getters.userInfo.venue_id;
    },
    ticketModal: {
      get() {
        return this.packagesModal
      },
      set(value) {
        this.$emit('close', value)
      }
    },
    promotions() {
      return this.$store.getters.getShopPromotions.data;
    },
  },
  data() {
    return {
      offers: [],
      showOfferPopup: false,
      showCustomerPopup: false,
      start_date: moment().format('YYYY-MM-DD'),
      installmentPlansModal: false,
      selectedPackage: null,
      selectedPlan: null,
      bookingForm:{
        venue_id: null,
        products: [],
        discount: null,
        promotion_code: null,
        membership_name: null,
        package_name: null,
        membership_id: null,
        membership_package_id: null,
        membership_package_installment_id: null
      }
    }
  },
  watch: {
    selectedPackage: function (val) {
      if (val === null) {
        this.selectedPlan = null;
        this.installmentPlansModal = false;
        return;
      }
      this.selectedPlan = this.selectedPackage.plans.find(plan => plan.divisor === 1) || null;
    }
  },
  methods: {
    setCustomers(customers) {
      let cartData = {products: []};
      cartData.venueId=this.getCurrentVenueId;
      cartData.type = "membership";
      cartData.membership_name = this.bookingForm.membership_name;
      cartData.package_name = this.bookingForm.package_name;
      cartData.membership_id = this.bookingForm.membership_id;
      cartData.membership_package_id = this.bookingForm.membership_package_id;
      cartData.membership_package_installment_id = this.bookingForm.membership_package_installment_id;
      cartData.start_date = this.start_date;
      cartData.discount = this.bookingForm.discount?this.bookingForm.discount:null;
      
      if(this.bookingForm.promotion_code){
        cartData.promotion_code = this.bookingForm.promotion_code;
      }
      this.bookingForm.quantity = 1;
    
      this.bookingForm.name = this.bookingForm.products[0].product_name;
      this.bookingForm.products[0].name = this.bookingForm.products[0].product_name;
      customers.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      cartData.customers = customers;
      let pushObject = this.bookingForm.products.filter(ele => ele.quantity > 0)
      cartData.products = [...cartData.products, ...pushObject];
      this.$store.dispatch("addToCart", {...cartData}).then( () => {
        this.checkProductOffers();
      });
      this.showSuccess("Package added to cart successfully");
      this.showCustomerPopup = false;
      this.installmentPlansModal = false;
      this.ticketModal = false;
      this.resetBookingForm();
    
    },
    getMaxCustomers() {
      return this.selectedPlan.member_limit;
    },
    closeCustomerPopup() {
      this.resetBookingForm();
      this.showCustomerPopup = false;
    },
    showPlans(pkg) {
      this.selectedPackage = pkg;
      this.installmentPlansModal = true;
    },
    enrollNow(plan) {
      this.selectedPlan = plan;
      this.bookingForm.membership_name = this.membership.name;
      this.bookingForm.package_name = this.selectedPackage.name;
      this.bookingForm.membership_id = this.membership.id;
      this.bookingForm.membership_package_id = this.selectedPackage.id;
      this.bookingForm.membership_package_installment_id = this.selectedPlan.id;
      this.bookingForm.venue_id = this.getCurrentVenueId;
      
      let productObj = {
        id: this.selectedPlan.product_id,
        product_id: this.selectedPlan.product_id,
        quantity: 1,
        total_price: this.selectedPlan.total_price / this.selectedPlan.divisor,
        tax: this.selectedPlan.tax / this.selectedPlan.divisor,
        tax_amount: this.selectedPlan.tax / this.selectedPlan.divisor,
        total_amount: this.selectedPlan.price?this.selectedPlan.price/this.selectedPlan.divisor:null,
        price: this.selectedPlan.price / this.selectedPlan.divisor,
        product_type_id: this.selectedPlan.product_type_id,
        membership_id:this.membership.id,
        product_name: this.selectedPlan.name,
        
      }
      this.bookingForm.products.push({...productObj});
      this.$store.dispatch("loadShopPromotions", {
        membership_id: this.membership.id,
        product_id: plan.product_id,
        product_type: "membership",
        venueId: this.getCurrentVenueId
      }).then( () => {
        this.showCustomerPopup = true;
      });
      this.ticketModal = false;
    },
    resetBookingForm(){
      this.bookingForm = {
        venue_id: null,
        products: [],
        discount: null,
        promotion_code: null,
        membership_name: null,
        package_name: null,
        membership_id: null,
        membership_package_id: null,
        membership_package_installment_id: null
      }
    },
    checkProductOffers(){
      this.offers = [];
      this.$store.dispatch('searchOffer').then( (response) => {
        if (response && response.status == 200 && response.data.status == true) {
          const offerPackages =response.data.data || null;
          if(offerPackages && offerPackages.length > 0) {
            this.offers = offerPackages;
            this.showOfferPopup = true;
          }else{
            this.offers = [];
          }
        }
      });
    },
    closeOfferPopup(){
      this.showOfferPopup = false;
      this.offers = [];
    },

  }
}
</script>
<style lang="scss" scoped>
.package-card {
  padding-bottom: 1rem;
  border-radius: 1rem;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  border: 0.5px solid #112A46;

  .package-title {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    font-size: 1.125rem;
    line-height: 1.25rem;
    font-weight: 600;
    text-align: center;
    border-bottom: 0.5px solid rgba(17, 42, 70, 0.3);
  }
}

.installment-list {
  margin-top: 1rem;

  li {
    font-size: 1rem;
    font-weight: 400;
    margin-bottom: 0.75rem;

    &::marker {
      background: #112A46;
      line-height: 1rem;
      margin-right: 0.5rem;
      width: 0.5rem;
      height: 0.5rem;
      border-radius: 100%;
    }
  }
}

.installment-button {
  &:before {
    display: none !important;
  }
}
</style>