<template>
  <div>
    <div class="cart-payment shadow-2">
      <div class="pa-4 border-bottom">
        <h4 class="text-2xl font-semibold mb-10">
          Order Summary
        </h4>
        <div v-for="(order,index) in getCartItems" :key="`os_${index}`" class="os-cart-item">
          <div v-for="product in order.products" :key="product.id"
               class="d-flex justify-space-between align-center mb-4">
            <p class="mb-0">
              {{ product.quantity }} x {{ product.name }}
            </p>
            <p v-if="product.quantity < 1" class="mb-0 font-medium">
              <span v-if="product.discount">
                <span class="discount-item">{{ product.discount.actual_total | toCurrency }} </span>
                <span class="item-amount">{{ product.total_price | toCurrency }}</span>
              </span>
              <span v-else-if="product.offer_package_id">
                <span class="discount-item">{{ (product.total_price * product.quantity) | toCurrency }} </span>
                <span class="item-amount">{{ getOfferCalculatedPrice(product) | toCurrency }}</span>
              </span>
              <span v-else class="item-amount">{{ product.total_price | toCurrency }}</span>
            </p>
            <p v-else class="mb-0 font-medium">
              <span v-if="product.discount">
                 <span class="discount-item">{{ product.discount.actual_total | toCurrency }} </span>
                 <span class="item-amount">{{ (product.total_price * product.quantity) | toCurrency }}</span>
              </span>
              <span v-else-if="product.offer_package_id">
                  <span class="discount-item">{{ (product.total_price * product.quantity) | toCurrency }} </span>
                  <span class="item-amount">{{ getOfferCalculatedPrice(product) | toCurrency }}</span>
              </span>
              <span class="item-amount" v-else>{{ (product.total_price * product.quantity) | toCurrency }}</span>
            </p>
          </div>
        </div>
      </div>
      <div class="pa-4 border-bottom">
        <div class="d-flex justify-space-between align-center mb-4">
          <p class="mb-0">
            Sub Total
          </p>
          <p class="mb-0">
            {{ subTotal.toFixed(2) | toCurrency }}
          </p>
        </div>
        <div class="d-flex justify-space-between align-center mb-4">
          <p class="mb-0">
            Tax
          </p>
          <p class="mb-0">
            {{ tax.toFixed(2) | toCurrency }}
          </p>
        </div>
      </div>
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center mb-4">
          <p class="mb-0">
            Order Total
          </p>
          <p class="mb-0 text-xl font-semibold">
            {{ total.toFixed(2) | toCurrency }}
          </p>
        </div>
        <!-- <div class="d-flex justify-space-between align-center mb-4" v-if="totalDiscount > 0">
          <p class="mb-0">
           Total Discount
          </p>
          <p class="mb-0 text-xl font-semibold">
            {{ totalDiscount.toFixed(2) | toCurrency }}
          </p>
        </div> -->
        <div class="payment-button">
          <!-- <v-text-field
              class="w-full promo-codes q-text-field shadow-0"
              dense
              hide-details="auto"
              outlined
              placeholder="Promo Code"
          >
            <template v-slot:append>
              <v-btn
                  color="primary"
                  height="40"
                  text
              >
                Apply
              </v-btn>
            </template>
          </v-text-field>
          -->
          <div v-if="appliedPromo.size" class="mt-2">
            Promotions Applied: <span v-for="(promo,i) in appliedPromo" :key="i" class="promo">{{ promo }}</span>
          </div>
<!--          :disabled="$store.getters.getCartItems.length == 0?true:false"-->
<!--          @click="openMultiInvoice(73389)"-->
          <v-btn
              :disabled="$store.getters.getCartItems.length == 0?true:false"
              class="w-full mt-6 blue-color white--text"
              height="48"
              text
              @click="confirmBooking"
          >
            Confirm Booking
          </v-btn>
        </div>
      </div>
    </div>
    <order-details
        :id="orderId"
        :ids="orderIds"
        :invId="invoiceModel.invoiceId"
        :invIds="invoiceModel.invoiceIds"
        :log="true"
        :eInvId="eInvoiceModel.invoiceId"
        :payInvId="paymentModel.invoiceId"
        :creditInvId="creditInvoiceId"
        @openMainOrderDetails="openMainOrderDetails"
        @closeMainComponent="closeMainComponent"
        @invoiceReload="invoiceReload"
        @close="closeMainComponent"
    ></order-details>
    <MultiInvoiceModel :multiInvoiceDialog="multiInvoiceDialog" :multiInvoiceData="multiInvoiceData" @close="closeMultiInvoice" @payed="openPayedInvoice"/>
    <PackageCardPopup :show-offer-popup="showOfferPopup" :offers="offers" @close="close"/>
  </div>
</template>

<script>
import moment from "moment";
import OrderDetails from "@/components/Order/OrderDetails.vue";
import MultiInvoiceModel from "@/components/Invoice/MultiInvoiceModel.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";

export default {
  components: {OrderDetails, MultiInvoiceModel, PackageCardPopup},
  computed: {
    getCurrentVenueId() {
      return this.$store.getters.svid;
    },
    moment() {
      return moment
    },
    getCartItems() {
      return this.$store.getters.getCartItems;
    },
    appliedPromo() {
      // get from stores
      let items = this.$store.getters.getCartItems;
      let p = new Set();
      items.filter(i => i.promotion_code).forEach((promo) => {
        p.add(promo.promotion_code)
      });
      return p;

    },
    subTotal() {
      return this.getCartItems.reduce((totalPrice, item) => {
        return totalPrice + item.products.reduce((productTotal, product) => {
          let qty = product.quantity < 1 ? 1 : product.quantity;
          let amount = 0;

          if (product.offer_package_id && product.offer) {
            if (qty > product.offer.quantity) {
              qty -= product.offer.quantity;
              amount = (product.price * qty) + product.offer.price;
            } else {
              amount = product.offer.price; // Apply the offer price if quantity does not exceed offer quantity
            }
          } else {
            amount = product.price * qty;
          }
          return productTotal + amount;
        }, 0);
      }, 0);
    },
    tax() {
      // let totalTax = this.getCartItems.reduce((st, item) => {
      //   return st + item.products.reduce((acc, product) => {
      //     return acc + product.tax * (product.quantity < 1 ? 1 : product.quantity);
      //   }, 0);
      // }, 0);
      // return totalTax;

      return this.getCartItems.reduce((totalPrice, item) => {
        return totalPrice + item.products.reduce((productTotal, product) => {
          let qty = product.quantity < 1 ? 1 : product.quantity;
          let amount = 0;

          if (product.offer_package_id && product.offer) {
            if (qty > product.offer.quantity) {
              qty -= product.offer.quantity;
              amount = (product.tax * qty) + product.offer.tax;
            } else {
              amount = product.offer.tax; // Apply the offer price if quantity does not exceed offer quantity
            }
          } else {
            amount = product.tax * qty;
          }
          return productTotal + amount;
        }, 0);
      }, 0);

    },
    total() {
      return this.subTotal + this.tax
    },
    totalDiscount() {
      let totalDiscountedAmount = 0;
      this.getCartItems.filter((item) => item.discount).forEach((d) => {
        d.products.forEach((p) => {
          if (p.discount) {
            totalDiscountedAmount += (p.total_price * (p.quantity < 1 ? 1 : p.quantity));
          }
        })
      });
      return totalDiscountedAmount;
    },
  },
  data() {
    return {
      orderId: null,
      creditInvoiceId: null,
      orderIds: [],
      invoiceIds: [],
      invoiceData: {},
      invoiceModel: {invoiceId: null, type: "details", orderIds: []},
      paymentModel: {invoiceId: null, type: "details", orderIds: []},
      payInvoiceData: {},
      eInvoiceModel: {invoiceId: null, type: "details", orderIds: []},
      confirmModel: {
        id: null,
        title: null,
        description: null,
      },
      multiInvoiceDialog: false,
      multiInvoiceData: [],
      offers: [],
      page: 1,
      totalPages: 1,
      showOfferPopup: false,
    }
  },
  methods: {
    confirmBooking() {
      this.showLoader('Processing');
      let orders = this.$store.getters.getCartItems;
      if ((orders && orders.length < 1) || !orders) {
        this.showError('Sorry! Your cart is empty');
        return;
      }
      var formData = new FormData();

      let filterNotRetailOrders = orders.filter( (o) => o.type !== 'retail');
      let filterRetailOrders = orders.filter( (o) => o.type === 'retail');
      filterNotRetailOrders.forEach((item, itemIndex) => {
        formData.append(`orders[${itemIndex}][type]`, item.type);
        formData.append(`orders[${itemIndex}][venueId]`, item.venueId);
        formData.append(`orders[${itemIndex}][venue_service_id]`, item.venue_service_id ? item.venue_service_id : null);
        if (item.promotion_code) {
          formData.append(`orders[${itemIndex}][promotion_code]`, item.promotion_code);
        }
       if (item.type === 'facility') {
          this.setFacilityBookingData(item, itemIndex, formData);
        } else if (item.type === 'trainer') {
          this.setTrainerBookingData(item, itemIndex, formData);
        } else if (item.type === 'event') {
          this.setEventBookingData(item, itemIndex, formData);
        } else if (item.type === 'academy') {
          this.setAcademyBookingData(item, itemIndex, formData);
        } else if (item.type === 'membership') {
          this.setMembershipBookingData(item, itemIndex, formData);
        }
        this.filterDiscountData(item, itemIndex, formData);
        this.filterCustomerData(item, itemIndex, formData);
        this.filterProductData(item, itemIndex, formData);
       });

      let retailOrders = [];
      filterRetailOrders.forEach( (item) => {
          this.setRetailBookingData(item,retailOrders);
      });
      if(retailOrders && retailOrders.length > 0) {
        let newOrderIndex = filterNotRetailOrders && filterNotRetailOrders.length?filterNotRetailOrders.length: 0;
        retailOrders.forEach( (ro,roi) => {
          formData.append(`orders[${newOrderIndex + roi}][type]`, ro.type);
          formData.append(`orders[${newOrderIndex + roi}][venueId]`, ro.venueId);
          formData.append(`orders[${newOrderIndex + roi}][venue_service_id]`, ro.venue_service_id ? ro.venue_service_id : null);
          this.filterCustomerData(ro, newOrderIndex + roi, formData);
          this.filterProductData(ro, newOrderIndex + roi, formData);
        });
      }
      this.sendBookingData(formData);
    },

    setFacilityBookingData(item, itemIndex, formData) {
      if (item.type === 'facility') {
        formData.append(`orders[${itemIndex}][facility_id]`, item.facility_id);
        formData.append(`orders[${itemIndex}][date]`, item.date);
        formData.append(`orders[${itemIndex}][start_date]`, item.start_date);
        formData.append(`orders[${itemIndex}][start_time]`, item.start_time);
        formData.append(`orders[${itemIndex}][end_time]`, item.end_time);

        if (item.repeat) {
          this.setRepeatBookingData(item, itemIndex, formData);
        }
        // for (let key in item) {
        //   if (item[key] != null && !Array.isArray(item[key]) && typeof item[key] !== "object") {
        //     formData.append(`orders[${itemIndex}][${key}]`, item[key]);
        //   } else if (Array.isArray(item[key])) {
        //     item[key].forEach((data, index) => {
        //       if (!Array.isArray(data) && typeof data === "object") {
        //         for (let innerKey in data) {
        //           if (Array.isArray(data[innerKey])) {
        //             data[innerKey].forEach((deepData, deepIndex) => {
        //               formData.append(`orders[${itemIndex}][${key}][${index}][${innerKey}][${deepIndex}]`, typeof deepData === "object" ? JSON.stringify(deepData) : deepData);
        //             });
        //           } else if(typeof data[innerKey] === "object"){
        //             for (let dik in data[innerKey]) {
        //               formData.append(`orders[${itemIndex}][${key}][${index}][${innerKey}][${dik}]`, data[innerKey][dik]);
        //             }
        //           }else {
        //             formData.append(`orders[${itemIndex}][${key}][${index}][${innerKey}]`, data[innerKey]);
        //           }
        //         }
        //       } else if (Array.isArray(data)) {
        //         data.forEach((innerData, innerIndex) => {
        //           formData.append(`orders[${itemIndex}][${key}][${index}][${innerIndex}]`, innerData);
        //         });
        //       } else {
        //         formData.append(`orders[${itemIndex}][${key}][${index}]`, typeof data === "object" ? JSON.stringify(data) : data);
        //       }
        //     });
        //   } else if(typeof item[key] === "object" && key == 'discount'){
        //     for (let innerKey in item[key]) {
        //       formData.append(`orders[${itemIndex}][discount][${innerKey}]`, item[key][innerKey]);
        //     }
        //   }
        // }
      }
      return formData;
    },
    setRepeatBookingData(item, itemIndex, formData) {
      const repeats = this.$store.getters.getRepeats;
      repeats.forEach((data, index) => {
        for (let innerKey in data) {
          if (["start_times", "end_times", "times"].includes(innerKey)) {
            continue;
          }
          if (Array.isArray(data[innerKey])) {
            data[innerKey].forEach((deepData, deepIndex) => {
              if (typeof deepData === "object") {
                for (let dik in deepData) {
                  formData.append(`orders[${itemIndex}][repeats][${index}][${innerKey}][${deepIndex}][${dik}]`, deepData[dik]);
                }
              } else {
                formData.append(`orders[${itemIndex}][repeats][${index}][${innerKey}][${deepIndex}]`, deepData);
              }
            });
          } else if (typeof data[innerKey] === "object") {
            for (let dik in data[innerKey]) {
              formData.append(`orders[${itemIndex}][repeats][${index}][${innerKey}][${dik}]`, data[innerKey][dik]);
            }
          } else {
            formData.append(`orders[${itemIndex}][repeats][${index}][${innerKey}]`, data[innerKey]);
          }
        }
      });
    },
    setTrainerBookingData(item, itemIndex, formData) {
      if (item.type === 'trainer') {
        formData.append(`orders[${itemIndex}][trainer_id]`, item.trainer_id);
        formData.append(`orders[${itemIndex}][package_id]`, item.package_id);
        formData.append(`orders[${itemIndex}][product_id]`, item.product_id);
      }
      return formData;
    },
    setEventBookingData(item, itemIndex, formData) {
      if (item.type === 'event') {
        formData.append(`orders[${itemIndex}][event_id]`, item.event_id);
        formData.append(`orders[${itemIndex}][date]`, item.date);
      }
      return formData;
    },
    setAcademyBookingData(item, itemIndex, formData) {
      if (item.type === 'academy') {
        formData.append(`orders[${itemIndex}][workshop_id]`, item.workshop_id);
        formData.append(`orders[${itemIndex}][workshop_type_id]`, item.workshop_type_id);
        formData.append(`orders[${itemIndex}][start_date]`, item.start_date);
        formData.append(`orders[${itemIndex}][program_id]`, item.program_id);
        formData.append(`orders[${itemIndex}][product_id]`, item.product_id);
        formData.append(`orders[${itemIndex}][package_type]`, item.package_type);
        formData.append(`orders[${itemIndex}][level_id]`, item.level_id);

        const date_ranges = item.date_ranges;
        date_ranges.forEach((data, index) => {
          if (typeof data === "object") {
            for (let innerKey in data) {
              if (Array.isArray(data[innerKey])) {
                data[innerKey].forEach((deepData, deepIndex) => {
                  formData.append(`orders[${itemIndex}][date_ranges][${index}][${innerKey}][${deepIndex}]`, typeof deepData === "object" ? JSON.stringify(deepData) : deepData);
                });
              } else if (typeof data[innerKey] === "object") {
                for (let dik in data[innerKey]) {
                  formData.append(`orders[${itemIndex}][date_ranges][${index}][${innerKey}][${dik}]`, data[innerKey][dik]);
                }
              } else {
                formData.append(`orders[${itemIndex}][date_ranges][${index}][${innerKey}]`, data[innerKey]);
              }
            }
          }
        });
        return formData;

      }
      return formData;
    },
    setMembershipBookingData(item, itemIndex, formData) {
      if (item.type === 'membership') {
        formData.append(`orders[${itemIndex}][membership_id]`, item.membership_id);
        formData.append(`orders[${itemIndex}][membership_package_id]`, item.membership_package_id);
        formData.append(`orders[${itemIndex}][membership_package_installment_id]`, item.membership_package_installment_id);
        formData.append(`orders[${itemIndex}][start_date]`, item.start_date);
      }
      return formData;
    },
    setRetailBookingData(item,retailOrders){
      if (item.type === 'retail') {
       item.products.forEach( (p) => {
         let obj = {
           'type': item.type,
           'venueId': item.venueId,
           'venue_service_id' : item.venue_service_id?item.venue_service_id:null,
           'products': [],
           'customers': [],
         }
         if(p.offer){
           obj.offer_package_id = p.offer_package_id;
           obj.products.push(p);
           obj.customers = item.customers;
           retailOrders.push(obj);
         }else{
            let findIndex = retailOrders.findIndex( (rd) => rd.venueId === item.venueId && !rd.offer_package_id);
            if(findIndex >= 0){
              retailOrders[findIndex].products.push(p);
            }else{
              obj.products.push(p);
              obj.customers = item.customers;
              retailOrders.push(obj);
            }
         }
       });
       return retailOrders;
    }},
    sendBookingData(formData) {
      this.$http.post(`venues/shop/make-booking`, formData, {headers: {"Content-Type": "multipart/form-data; boundary=${form._boundary}",},})
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              let data = response.data.data;
              // console.log(data);
              this.hideLoader();
              this.showSuccess("Booking saved successfully");
              this.$store.dispatch('resetCart');

              this.invoiceIds = data.invoices;
              if (data.invoices.length > 1) {
                this.openMultiInvoice(this.invoiceIds[0]);
              } else {
                this.openInvoice(this.invoiceIds[0]);
              }
              this.$emit('bookingConfirm');
            }
          }).catch((error) => {
        this.hideLoader();
        this.errorChecker(error);
      });
    },
    invoiceReload(id) {
      this.$http
          .get(`venues/invoices/${id}`)
          .then((response) => {
            this.hideLoader();
            if (response.status == 200 && response.data.status) {
              let data = response.data.data;
              this.invoiceData = data;
              this.invoiceModel.invoiceId = data.id;
              this.invoiceModel.orderIds = this.selectedOrders;
            } else {
              this.invoiceModel.invoiceId = null;
              this.invoiceModel.orderIds = [];
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });
    },
    openInvoice(invoice_id, type = "view") {
      if(type === 'view') {
        this.invoiceModel.invoiceId = invoice_id;
      }else{
        this.invoiceModel.invoiceId = null;
      }

      // this.showLoader("Loading..");
      // this.$http
      //     .get("venues/invoices/" + invoice_id)
      //     .then((response) => {
      //       if (response.status == 200) {
      //         this.hideLoader();
      //         let data = response.data.data;
      //         if (type == "view") {
      //           this.c = data;
      //           console.log("invoiceData");
      //           console.log(data);
      //           this.invoiceModel.invoiceId = data.id;
      //           this.invoiceModel.orderIds = [];
      //           this.paymentModel.invoiceId = null;
      //         }
      //       }
      //     })
      //     .catch((error) => {
      //       this.hideLoader();
      //       this.errorChecker(error);
      //     });
    },
    closeMainComponent() {
      this.orderId = null;
      this.orderIds = null;
      this.invoiceModel.invoiceId = null;
      this.invoiceModel.invoiceIds = [];
      this.eInvoiceModel.invoiceId = null;
      this.paymentModel.invoiceId = null;
      this.creditInvoiceId = null;
      this.multiInvoiceDialog = false;
      this.multiInvoiceData = [];
    },
    openMainOrderDetails(id) {
      this.invoiceModel.invoiceId = null;
      this.eInvoiceModel.invoiceId = null;
      this.olModel.invoiceId = null;
      this.orderId = null;
      this.orderIds = null;
      this.orderId = id;
    },
    filterCustomerData(item, itemIndex, formData) {
      const customers = item.customers;
      customers.forEach((data, index) => {
        if (!Array.isArray(data) && typeof data === "object") {
          for (let innerKey in data) {
            console.log(typeof data[innerKey], innerKey);

            if (Array.isArray(data[innerKey])) {
              data[innerKey].forEach((deepData, deepIndex) => {
                if(innerKey === "customer_tag" || innerKey === "customer_documents"){
                  for (let dik in deepData) {
                    formData.append(`orders[${itemIndex}][customers][${index}][${innerKey}][${deepIndex}][${dik}]`, deepData[dik]);
                  }
                }else{
                  formData.append(`orders[${itemIndex}][customers][${index}][${innerKey}][${deepIndex}]`, typeof deepData === "object" ? JSON.stringify(deepData) : deepData);
                }
              });
            } else if (typeof data[innerKey] === "object") {
              if(innerKey === "profile_image"){
                formData.append(`orders[${itemIndex}][customers][${index}][${innerKey}]`, data[innerKey]);
              }else {
                for (let dik in data[innerKey]) {
                  formData.append(`orders[${itemIndex}][customers][${index}][${innerKey}][${dik}]`, data[innerKey][dik]);
                }
              }
            } else {
              formData.append(`orders[${itemIndex}][customers][${index}][${innerKey}]`, data[innerKey]);
            }
          }
        } else if (Array.isArray(data)) {
          data.forEach((innerData, innerIndex) => {
            formData.append(`orders[${itemIndex}][customers][${index}][${innerIndex}]`, innerData);
          });
        } else {
          formData.append(`orders[${itemIndex}][customers][${index}]`, typeof data === "object" ? JSON.stringify(data) : data);
        }
      });
      return formData;

    },
    filterDiscountData(item, itemIndex, formData) {
      if (item.discount) {
        const discounts = item.discount;
        for (const innerKey in discounts) {
          formData.append(`orders[${itemIndex}][discount][${innerKey}]`, discounts[innerKey]);
        }
      }
      return formData;
    },
    filterProductData(item, itemIndex, formData) {
      const products = item.products;
      products.forEach((product, index) => {
        if (product.discount) {
          const discounts = product.discount;
          for (const innerKey in product.discount) {
            formData.append(`orders[${itemIndex}][products][${index}][discount][${innerKey}]`, discounts[innerKey]);
          }
        }else if(product.offer && product.offer_package_id){
          const offer = product.offer;
          formData.append(`orders[${itemIndex}][products][${index}][offer_package_id]`, offer.offer_package_id);
          for (const innerKey in product.offer) {
            formData.append(`orders[${itemIndex}][products][${index}][offer][${innerKey}]`, offer[innerKey]);
          }
        }
        formData.append(`orders[${itemIndex}][products][${index}][product_id]`, product.product_id);
        formData.append(`orders[${itemIndex}][products][${index}][product_type_id]`, product.product_type_id);
        formData.append(`orders[${itemIndex}][products][${index}][quantity]`, product.quantity);
        formData.append(`orders[${itemIndex}][products][${index}][participant_count]`, product.participant_count ? product.participant_count : 1);
        if (item.type === 'facility') {
          formData.append(`orders[${itemIndex}][products][${index}][rental]`, product.rental ? product.rental : false);
          formData.append(`orders[${itemIndex}][products][${index}][category_id]`, product.category_id ? product.category_id : 0);

          if (product.inventory_enable) {
            formData.append(`orders[${itemIndex}][products][${index}][inventory_enable]`, product.inventory_enable ? product.inventory_enable : false);
          }
          if (product.is_full_day) {
            formData.append(`orders[${itemIndex}][products][${index}][is_full_day]`, product.is_full_day ? product.is_full_day : 0);
          }
          if (product.seasonal_pricing_id) {
            formData.append(`orders[${itemIndex}][products][${index}][seasonal_pricing_id]`, product.seasonal_pricing_id ? product.seasonal_pricing_id : null);
          }
          //  open product and addon
          if (product.product_id === 0) {
            formData.append(`orders[${itemIndex}][products][${index}][price]`, (product.price * product.quantity));
            formData.append(`orders[${itemIndex}][products][${index}][product_price]`, product.product_price);
            formData.append(`orders[${itemIndex}][products][${index}][name]`, product.name);
            formData.append(`orders[${itemIndex}][products][${index}][tax]`, (product.tax * product.quantity));
            formData.append(`orders[${itemIndex}][products][${index}][tax_amount]`, product.tax_amount);
            formData.append(`orders[${itemIndex}][products][${index}][total_price]`, (product.total_price * product.quantity));
          }
        } else if (item.type === "event") {
          formData.append(`orders[${itemIndex}][products][${index}][event_ticket_id]`, product.event_ticket_id);
        } else if (item.type === "retail") {
          formData.append(`orders[${itemIndex}][products][${index}][vp_id]`, product.vp_id);
          formData.append(`orders[${itemIndex}][products][${index}][enable_retail_inventory]`, product.enable_retail_inventory);
        }

      });
      return formData;
    },
    openMultiInvoice(invoiceId = null) {
      this.multiInvoiceDialog = false;
      if (!invoiceId) {
        return this.showError("Invoice not found");
      }
      this.showLoader("Loading..");
      this.$http
          .get(`venues/invoices/batch-invoice/type/invoice/${invoiceId}`)
          .then((response) => {
            if (response.status == 200) {
              this.hideLoader();
              let data = response.data.data;
              this.multiInvoiceData = data;
              this.multiInvoiceDialog = true;
            }
          })
          .catch((error) => {
            this.hideLoader();
            this.errorChecker(error);
          });

    },
    closeMultiInvoice() {
      this.multiInvoiceData = [];
      this.multiInvoiceDialog = false;
    },
    openOffersDialog() {
      this.getAllOffers();
    },
    getAllOffers() {
      this.offers = [];
      const venueId = this.getCurrentVenueId;
      console.log(venueId);
      this.showLoader('Loading Offers');
      this.$http
          .get(`venues/shop/offers?venueId=${venueId}`, {
            params: {
              page: this.page,
              search: this.search,
            }
          })
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data
              this.offers = data.data;
              this.totalPages = data.last_page;
              this.showOfferPopup = true;
              this.$forceUpdate();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          })
          .finally(() => {
            this.hideLoader();
          });
    },
    getOfferCalculatedPrice(product) {
      if (product.offer_package_id) {
        if (product.quantity >= 1) {
          let totalProductPrice = product.total_price * (product.quantity - product.offer.quantity);
          return totalProductPrice + product.offer.total_price;
        }
        return product.total_price;
      } else {
        return 0;
      }
    },
    close() {
      this.showOfferPopup = false;
    },
    openPayedInvoice(invId){
      this.openMultiInvoice(invId);
    }
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .os-cart-item {
    font-size: 14px;
  }

  .cart-payment {
    border-radius: 0.75rem;
    border: 1px solid #E8E8E8;
    background: #FFF;
    padding-bottom: 1.25rem;

    span.discount-item {
      text-decoration: line-through;
      font-size: 12px;
      color: #ff0606;
    }

    .payment-button {
      margin-top: 11.75rem;

      button.v-btn--disabled {
        color: #bbb !important;
      }

      .promo-codes {
        .v-input__slot {
          padding-right: 0 !important;
        }

        .v-input__append-inner {
          margin-top: 0 !important;
        }

        button {
          border-radius: 0.5rem;
          border: 1px solid #112A46;
          background: rgba(17, 42, 70, 0.10);
        }

      }

      .promo {
        background: #F8FAFB;
        padding: 0.125rem 0.25rem;
        border-radius: 0.75rem;
        min-width: 1.5rem;
        margin-right: 0.5rem;
        border: 0.5px solid #112A46;
        font-size: 0.75rem;
        text-transform: capitalize;
      }
    }
  }
}
</style>